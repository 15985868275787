.authenticator {
    font-family: Poppins, sans-serif !important;
}

.authenticator .amplify-button:not(.amplify-field__show-password) {
    background-color: #462383 !important;
    color: #ffffff !important;
    padding: 10px 20px !important;
    border-radius: 20px !important;
    transition: background-color 0.5s ease, color 0.5s ease, border 0.5s ease !important;
    text-decoration: none !important;
    font-family: Poppins, sans-serif !important;
    border: 2px solid transparent;
}

.authenticator .amplify-button:not(.amplify-field__show-password):hover {
    background-color: #ffffff !important;
    color: #462383 !important; 
    border: 2px solid #462383 !important;
}